.container {
  display: flex;
  flex-direction: column;
}

.first_row {
  display: flex;
  flex-direction: row;
}

.character {
  width: 35%;
}

.summary {
  width: 40%;
}

.event {
  width: 25%;
}

.map {
  width: 50%;
}

.location {
  width: 50%;
}

@media only screen and (max-width: 1000px) {
  .first_row {
    flex-direction: column;
  }
  .character {
    width: 100%;
  }

  .summary {
    width: 100%;
  }

  .event {
    width: 100%;
  }
  .map {
    width: 100%;
  }

  .location {
    width: 100%;
  }
}
